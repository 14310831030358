<template>
   <div>
     <v-banner
        single-line
        height="400"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerOnlineRetailers400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex max-width-1200">
          <v-card color="transparent" flat class="align-center">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  <!-- 电商营销 -->
                  电商零售行业
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  AR互动游戏、VR全景店铺、360商品展示，结合实物、<br/>优惠券、积分等多种奖励方式，助力电商零售行业提升销售转化
                </v-card-title>
                <!-- <v-card-actions>
                  <v-btn
                      width="160" height="50"
                      class="ml-2 text-h5 font-weight-regular pa-4 rounded-pill"
                  >
                    <span class="font-weight-regular banner_span">申请开通</span> 
                  </v-btn>
                </v-card-actions> -->
              </div>
          
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container class="containerStyle max-width-1200" height='400' >
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg" >
          <img src="https://h5.ophyer.cn/official_website/other/Online1.png"/>
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">720°全景店铺打破空间限制，重塑购物体验</v-card-title>
            <!-- <v-card-title  class="h4 pa-0">打破空间限制，重塑购物体验</v-card-title> -->
            <v-card-text  class="con_text pa-0">720°沉浸式虚拟全景店铺，添加商品/优惠券热点/360商品展示<br/>，提升用户真实体验感与消费舒适度，带来全新的购物体验</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/Online2.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <!-- <v-card-title class="con_tit pa-0 mb-5" >结合互动游戏实现营销效果翻倍</v-card-title> -->
            <v-card-title class="con_tit pa-0 mb-5" >结合互动游戏实现运营效果翻倍</v-card-title>
            <v-card-text class="con_text pa-0">利用AR趣味互动游戏结合实物、优惠券/卡密、积分等多种<br/>奖励方式，轻松获取促进下单，满足商家引流获客</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/Online3.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">360°全方位展示,商品信息更直观</v-card-title>
            <v-card-text  class="con_text pa-0">360°全方位展示商品，让您的商品纤毫毕现。给用户更真实<br/>的细节展示，促进用户下单</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/Online4.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >多平台一键卖货</v-card-title>
            <v-card-text class="con_text pa-0">微信、QQ、头条、抖音等多平台，一键卖货<br/>全网引流</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/Online5.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">支持个性化定制满足多样化需求</v-card-title>
            <v-card-text  class="con_text pa-0">专属电商解决方案顾问1对1服务，支持定制开发，满<br/>足差异化需求</v-card-text>
          </v-card>
        </div>
      </v-card>

    </v-container>
    <!-- 精彩案列 -->
    <!--<v-container class="justify-center mb-6 wap_case" >-->
    <!--  <div class="public-title">-->
    <!--    <p>WONDERFUL CASE</p>-->
    <!--    <div>-->
    <!--      <span>精彩案例</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--   <v-container style="max-width:1200px;margin-top:62px">-->
    <!--        <v-row>-->
    <!--            <v-col v-for="(card, index) in anli" :key="index" cols="3" class="mt-4">-->
    <!--                <v-card-->
    <!--                  class="mx-auto item-card"-->
    <!--                  max-width="240"-->
    <!--                  max-height="410"-->
    <!--                  color="transparent"-->
    <!--                  @click="goCaseDetail(card)"-->
    <!--                >-->
    <!--                  <v-img-->
    <!--                    max-height="340"-->
    <!--                    :src="card.coverImg"-->
    <!--                    :aspect-ratio="9/16"-->
    <!--                  >-->
    <!--                  <template v-slot:placeholder>-->
    <!--                          <v-row class="fill-height ma-0" align="center" justify="center">-->
    <!--                            <v-progress-circular-->
    <!--                              indeterminate-->
    <!--                              size="80"-->
    <!--                              color="red lighten-5"-->
    <!--                            ></v-progress-circular>-->
    <!--                          </v-row>-->
    <!--                  </template>-->
    <!--                  </v-img>-->
    <!--                  <v-card-text class="font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>-->
    <!--                </v-card>-->
    <!--            </v-col>-->
    <!--        </v-row>-->
    <!--      </v-container>-->
    <!--</v-container>-->
    
   </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import qs from 'qs'
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      anli:[textData.anli.paopaomate,textData.anli.delongkafeiji,textData.anli.kidsyundian,textData.anli.aojiabaohuyan],
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>


.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
     display: none;
  }
}
.wap_case{
  .public-title{
    margin: 45px auto 65px !important;
  }

}
</style>